import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  weekdays: Array<string>;
  hours: Array<string>;
  constructor() {
    this.weekdays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    this.hours = ['CLOSED', '10AM - 6PM', '10AM - 6PM', '10AM - 6PM', '10AM - 6PM','9AM - 4PM', 'CLOSED'];
   }

  ngOnInit() {
  }

}
