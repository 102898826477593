import { Component,AfterViewInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit{
  title = 'salon-dimensions';
  constructor(private router:Router){}
  ngAfterViewInit(){
    if(window.location.href == 'http://localhost:4200/' || window.location.href == 'https://salondimensionsnova.com/')
    this.router.navigate(['/', 'home']);
  }
}