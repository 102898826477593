import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  gallery:Array<any>;
  constructor() { 
    this.gallery = [];
    var row = [];
    for(var i=0;i<22;i++){
      row.push('../../assets/gallery' + (i+1) + '.JPG');
      if(i%3 == 2){
        this.gallery.push(row);
        row = [];
      }
    }
    row.push('');
    row.push('');
    this.gallery.push(row);
  }

  ngOnInit() {

  }

}
