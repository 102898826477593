import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  content: any;
  constructor() {
    this.content = [{photo: "../../assets/cut.JPG", name:"Women's Cut and Style", cost:"$70 and Up"},
    {photo:"../../assets/blow&style.JPG", name: "Women's Blow Dry and Style", cost:"$45 and Up"}, 
    {photo:"../../assets/keratin.JPG", name: "Highlights - Full Head", cost:"$130 and Up"},
    {photo:"../../assets/halfHigh.jpg", name: "Highlights - Half Head", cost:"$80 and Up"},
    {photo:"../../assets/singlecolor.JPG", name: "Single Process Color", cost:"$80 and Up"},
    {photo:"../../assets/doublecolor.JPG", name: "Double Process Color", cost:"$130 and Up"},
    {photo:"../../assets/hairstyle.JPG", name: "Hair Style", cost:"$45 and Up"},
    {photo:"../../assets/updo.png", name: "Updo", cost:"$70"},
    {photo:"../../assets/perm.png", name: "Permanent", cost:"$120"},
    {photo:"../../assets/highlight.jpg", name: "Keratin Treatment", cost:"$350 and Up"},
    {photo:"../../assets/man.JPEG", name: "Men's Haircut", cost:"$40"},
    {photo:"../../assets/child.png", name: "Child's Haircut", cost:"$35 and Up"},
   ];
   }

  ngOnInit() {

  }
}
